/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/ArticleIndexPage";
import resolveImage from "@dataResolvers/resolveImage";
import resolveArticle from "@dataResolvers/resolveArticle";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ArticleIndexQuery {
    craft {
      entry(section: "articleIndex") {
        ... on Craft_articleIndex_articleIndex_Entry {
          # hero
          heroHeading: heading0
          heroDescriptor: descriptor0
          # content
          formHeading: heading1
          formDescriptor: descriptor1
          # meta
          title
          url
          theme
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(section: "articles") {
        ... on Craft_articles_article_Entry {
          uid
          date: postDate
          authors: person0 {
            title
          }
          url: uri
          type: typeHandle
          title
          descriptor: descriptor0
          image: image0 {
            ...ImageFragment
          }
          topics {
            title
            uid
          }
          metaImage {
            ...ImageFragment
          }
          metaDescription
        }
        ... on Craft_articles_whitepaper_Entry {
          uid
          url: uri
          type: typeHandle
          title
          date: postDate
          authors: person0 {
            title
          }
          descriptor: descriptor0
          image: image0 {
            ...ImageFragment
          }
          topics {
            title
            uid
          }
          metaImage {
            ...ImageFragment
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    // hero
    heroHeading,
    heroDescriptor,
    // content
    social,
    formHeading,
    formDescriptor,
    // meta
    url,
    title,
    theme,
    metaTitle,
    metaDescription,
    metaImage,
  } = entry;

  const articles = entries.map(resolveArticle);

  return {
    hero: {
      heading: heroHeading,
      descriptor: heroDescriptor,
    },
    articles,
    social,
    newsletter: {
      heading: formHeading,
      descriptor: formDescriptor,
    },
    theme,
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ArticleIndexQuery"
    {...props}
  />
);

export default Page;
