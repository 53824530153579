import React from "react";
import classNames from "classnames";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const PageHero = loadable(() => import("@organisms/PageHero"));
const BlogHero = loadable(() => import("@organisms/BlogHero"));
const ArticleList = loadable(() => import("@organisms/ArticleList"));
const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));

const ArticleIndexPage = ({ data }) => {
  const { hero, newsletter, articles, theme } = data;

  const background = {
    white: "bg-white",
    offwhite: "bg-offwhite",
    lightTeal: "bg-teal-light",
    lighterTeal: "bg-teal-lighter",
    darkTeal: "bg-teal-dark",
    darkerTeal: "bg-black",
  }[theme];

  return (
    <PageContainer overflow>
      {!!hero.heading && <PageHero noHeight {...hero} />}
      <div className={classNames("absolute inset-x-0 h-[6rem]", background)} />
      <BlogHero article={articles[0]} newsletter={newsletter} theme={theme} />
      <ArticleList items={articles} />
      <NewsletterSignup />
    </PageContainer>
  );
};

export default ArticleIndexPage;
