import resolveImage from "./resolveImage";

const resolveArticle = article => {
  const { image, metaImage, descriptor, metaDescription, topics } = article;

  return {
    ...article,
    topics: topics?.map(t => t.uid),
    descriptor: descriptor || metaDescription,
    image: resolveImage(image || metaImage),
  };
};

export default resolveArticle;
